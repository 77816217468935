<template>
  <main v-if="entity">
    <div class="order-detail">
      <div class="order-title">兑换详情</div>
      <div class="order-table">订单编号：{{ entity.orderNum }}</div>
      <div class="order-table">兑换积分：{{ entity.credit }}</div>
      <div class="order-table">
        下单时间： {{ dateFormat(entity.createTime) }}
      </div>
      <a
        href="https://tb.53kf.com/code/client/099be4cda651b04a92a215ad1e6edd8d8/1"
        >联系客服</a
      >
    </div>
    <div class="commodity-detail">
      <div class="commodity-title">商品信息</div>
      <div class="commodity-content">
        <div class="commodity-pic">
          <img :src="product.pic.indexOf('https://')!=-1?product.pic:'https://www.bzwz.com'+product.pic" alt="" />
        </div>
        <div class="commodity-table">产品名称：{{ product.title }}</div>
        <div class="commodity-table">产品规格：{{ product.spec }}</div>
        <div class="commodity-table">兑换积分：{{ product.needCredit }}</div>
      </div>
    </div>
    <div class="logistics-detail">
      <div class="logistics-title">配送信息</div>
      <div class="logistics-content">
        <div class="logistics-table">收货人：{{ entity.realName }}</div>
        <div class="logistics-table">联系电话：{{ entity.tel }}</div>
        <div class="logistics-table">
          收货地址：{{ entity.province }} {{ entity.city }}
          {{ entity.district }} {{ entity.address }}
        </div>

        <div v-if="entity.deliverNO">
          <div class="logistics-table">配送方式：{{ entity.deliverType }}</div>
          <div class="logistics-table">快递单号：{{ entity.deliverNO }}</div>
          <a
            href="/M/User/Wuliu?DeliverType=${entity.deliverType!}&DeliverNO=${entity.deliverNO!}"
            >物流详情</a
          >
        </div>
      </div>
    </div>
  </main>
  <loading :options="loadOption" v-else></loading>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import Toast from "@/components/UI/Toast";
import axios from "@/api/axios";
import { useRoute, useRouter } from "vue-router";
import Loading from "@/components/UI/Loading";
import qs from "qs";
import dateFormat from "@/utils/dateFormat";
export default defineComponent({
  name: "ExchangeDetail",
  components: {
    Loading,
  },
  setup() {
    const route = useRoute();
    const num = route.query.num;
    const entity = ref(null);
    const product = ref(null);
    axios
      .get(`/M/User/JifenDetail`, {
        params: {
          validate: true,
          num,
        },
      })
      .then((res) => {
        if (res.data.success) {
          entity.value = res.data.obj.entity;
          product.value = res.data.obj.product;
        } else {
          Toast({
            type: "error",
            title: res.data.msg,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
    const loadOption = {
      text: "加载中...",
      color: "#df0024",
      textColor: "#df0024",
    };

    return {
      entity,
      loadOption,
      dateFormat,
      product
    };
  },
});
</script>

<style lang="scss" scoped>
main {
  padding-bottom: 109.987px;
}

.order-detail {
  position: relative;
  box-sizing: border-box;
  padding: 15px 10.013px;
  border-bottom: 7.5px solid #f4f4f4;
  padding-right: 82.5px;
}

.order-title {
  color: #52ba65;
  font-size: 18px;
}
.order-table {
  font-size: 15px;
  margin-top: 10.013px;
  color: #666666;
}

.order-detail a {
  position: absolute;
  right: 10.013px;
  bottom: 10.013px;
  width: 85.013px;
  height: 28.012px;
  border-radius: 13.988px;
  border: solid 1px #cccccc;
  line-height: 28.012px;
  text-align: center;
  color: #666666;
  font-size: 15px;
  text-decoration: none;
  color: #666;
}

.commodity-detail,
.logistics-detail {
  box-sizing: border-box;
  padding: 0 10.013px;
}

.commodity-detail {
  border-bottom: 7.5px solid #f4f4f4;
}

.commodity-content {
  padding: 17.512px 0;
  padding-left: 100.012px;
  position: relative;
}

.commodity-pic {
  width: 90px;
  height: 90px;
  position: absolute;
  top: 17.512px;
  left: 10.013px;
  overflow: hidden;
}
.commodity-pic img {
  width: 100%;
}

.commodity-title,
.logistics-title {
  color: #444444;
  font-size: 15px;
  height: 40.012px;
  line-height: 40.012px;
  border-bottom: 1px solid #eee;
}

.commodity-table,
.logistics-table {
  color: #444444;
  font-size: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-top: 4.988px;
  margin-left: 10.013px;
}

.logistics-table {
  margin-left: 0;
}
</style>
